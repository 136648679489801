import { ApiService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

// import { getFileService } from '@/services'
// if (resp.status_code === STATUS_SUCCESS) {}
export const getFileService = async (filePath) => { 
  try {
    // Assuming you need to pass filePath as a parameter in the URL or body
    return await client.get(apiConstant.GET_FILE+`?filePath=${filePath}`)
  } catch (error) {
    throw new Error(error.message); // Ensure you're throwing an Error object with a message
  }
}

export const getPathFileService = async (filePath) => { 
  return apiConstant.GET_FILE+`?filePath=${filePath}`;
}