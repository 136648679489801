export function downloadFileAttached (file, resp) {  //file name,  resp = file object
    if (resp) { // Assuming the blob is in the data property of the response
      const url = window.URL.createObjectURL(new Blob([resp])); // Create a blob without specifying the file type
      const link = document.createElement('a');
      link.href = url;

      // Extract the filename from the filePath
      const filename = file.split('/').pop(); // This gets the last part after the last '/'

      link.setAttribute('download', filename); // Use the extracted filename
      document.body.appendChild(link);
      link.click();

      // Cleanup: remove the link and revoke the URL
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
      console.log('Download started');
    }
}