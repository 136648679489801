import Vue from 'vue';
import VueRouter from 'vue-router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import store from '@/store'

/* custom */
import '@/styles/custom.css';
import '@/styles/loading.css';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  base: '',
  routes: configRoutes()
});

function configRoutes() {
  return [
    // Main Layout
    {
      path: '/',
      component: () => import('@/pages/Main.vue'),
      children: [
        {
          path: '',
          name: 'knowledge',
          component: () => import('@/pages/knowledgeBase/knowledgeBase.vue')
        },
        {
          path: 'faq',
          name: 'faq',
          component: () => import('@/pages/faq/faq.vue')
        },
        {
          path: 'news',
          name: 'news',
          component: () => import('@/pages/news/news.vue')
        },
        {
          path: 'news/:id',
          name: 'news_detail',
          component: () => import('@/pages/news/detail.vue')
        },
        {
          path: 'public/consultation/:id',
          name: 'consultation_detail',
          component: () => import('@/pages/consultation/consultationDetail.vue')
        },
      ]
    },
    
    // No Layout Pages
    {
      path: '/',
      component: () => import('@/pages/Main_No_Layout.vue'),
      children: [
        {
          path: 'login',
          name: 'login',
          component: () => import('@/pages/login/login.vue')
        },
        {
          path: 'register',
          name: 'register',
          component: () => import('@/pages/register/register_step1.vue')
        },
        {
          path: 'confirm_otp',
          name: 'confirm_otp',
          component: () => import('@/pages/register/register_step2.vue')
        },
        {
          path: 'terms-of-service',
          name: 'terms-of-service',
          component: () => import('@/pages/login/termsOfService.vue')
        },
        {
          path: 'thaiId',
          name: 'thaiId',
          component: () => import('@/pages/login/thai_id.vue')
        }
      ]
    },

    // Member Login Layout
    {
      path: '/',
      component: () => import('@/pages/Main_login.vue'),
      children: [
        {
          path: 'member/profile',
          name: 'member_profile',
          meta: { loginReq: true },
          component: () => import('@/pages/profile/profile.vue')
        },
        {
          path: 'consultation/lists',
          name: 'consultation_lists',
          meta: { loginReq: true },
          component: () => import('@/pages/consultation/consultationLists.vue')
        },
        {
          path: 'consultation/:id',
          name: 'consultation_detail',
          meta: { loginReq: true },
          component: () => import('@/pages/consultation/consultationDetail.vue')
        },
        {
          path: 'consultation/create/step1',
          name: 'consultation_create_step1',
          meta: { loginReq: true },
          component: () => import('@/pages/consultation/consultationCreate1.vue')
        },
        {
          path: 'consultation/create/step2',
          name: 'consultation_create_step2',
          meta: { loginReq: true },
          component: () => import('@/pages/consultation/consultationCreate2.vue')
        },
        {
          path: 'consultation/create/step3',
          name: 'consultation_create_step3',
          meta: { loginReq: true },
          component: () => import('@/pages/consultation/consultationCreate3.vue')
        },
        {
          path: 'consultation/create/step4',
          name: 'consultation_create_step4',
          meta: { loginReq: true },
          component: () => import('@/pages/consultation/consultationCreate4.vue')
        }
      ]
    }
  ];
}

// Route Guard for Authentication
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.authModule.isMember // Replace this with the actual authentication check

  if (to.meta.loginReq && !isAuthenticated) {
    // If the route requires login and the user is not authenticated, redirect to login
    if (to.name !== 'login') {
      next({ name: 'login' });
    } else {
      next(); // If already at the login page, allow access
    }
  } else if (to.name === 'login' && isAuthenticated) {
    // If the user is authenticated and tries to go to login, redirect them to their profile
    next({ name: 'member_profile' });
  } else {
    
    if (to.fullPath === from.fullPath) {
      // Prevent navigation if the current and next pages are the same
      next(false);
    } else {
      // Proceed with the rest of the navigation logic
    }
    next();
  }
});


export default router;
