export const STATUS_SUCCESS = 'success'
export const STATUS_OK = 'ok'

export const apiConstant = {
  REFRESH_TOKEN: '/api/v1/auth/authenticateRefreshToken',
  LOGIN: '/api/v1/auth/login',
  REG_CREATE: '/api/v1/users/register',
  REG_OTP: '/registerSendOTP',
  REG_VERIFY_OTP: '/registerVerifyOtp',
  PROFILE: '/api/v1/users/getProfile',
  PROFIL_UPDATE: '/user/profile/update',
  USER_FORGET_PASS: '/user/forget_password',
  USER_VERIFY_OTP: '/user/verifyOTPPin',
  USER_NEW_PASSWORD: '/user/submitNewPassword',
  /* knowledge */
  KNOWLEDGE_LIST: '/api/v1/public/knowledge/getLastKnowledge',
  KNOWLEDGE_BY_ID: '/api/v1/public/knowledge/getKnowledgeById',

  /* FAQ */
  FAQ_LIST: '/api/v1/faq/getFAQs',

  /* NEW */
  NEWS_LIST: '/api/v1/public/news/getNews',
  NEWS_BY_ID: '/api/v1/public/news/getNewsById',
  
  /* Consultation */
  CONSULTATION_LIST: '/api/v1/consult/getConsultByUserId',
  CONSULTATION_TYPE_LIST: '/api/v1/consult/getAllConsultTopic',
  SET_CONSULTATION: '/api/v1/consult/userRequestConsultation',
  GET_CONSULTATION: '/api/v1/consult/getUserConsultById',
  GET_PUBLIC_CONSULTATION: '/api/v1/consult/getPublicConsultById',
  GET_EVALUATE_QUESTION: '/api/v1/evaluation/getEvaluateQuestion',
  SET_EVALUATE_SCORE: '/api/v1/evaluation/evaluation-score',

  /* user */
  SET_USER_PROFILE: '/api/v1/users/setProfile',
  SET_USER_ADDRESS: '/api/v1/users/setAddress',
  SET_USER_CHANGE_PASS: '/api/v1/users/changePassword',
  GET_ADDR_BY_CODE: '/api/v1/users/getProvinceDistrictsSubdistricts',

  /* file */
  GET_FILE: '/api/v1/public/download',

}
