// import { localStorageService } from '@/utils'
import {
  // SET_TOKEN,
  // SET_ISMEMBER,
  // CLEAR_SESSION,
  STATUS_SUCCESS,
  // REQUEST_PROFILE,
  STATUS_OK,
  SET_CONSULTATION,
  SET_CONSULTATION_LIST,
  SET_CONSULTATION_TYPE_LIST,
  SET_CONSULTATION_PROFILE_BUF,
  SET_CONSULTATION_DETAIL_BUF,
  SET_CONSULTATION_FILE_BUF,
  GET_CONSULTATION,
  GET_EVALUATE_QUESTION
} from '@/constants'
import { 
  getConsultByUserIdService, 
  getAllConsultTopicService, 
  userRequestConsultationService, 
  getUserConsultByIdService, 
  getEvaluateQuestionService,
  setEvaluationScoreService,
  getPublicConsultByIdService
 } from '@/services'
const consultationModule = {
  state: {
    questionList: [],
    consultationList: [],
    consultationTypeList: [],
    userConsultation: {
      profile: null,
      detail: null,
      files: null
    },
    consultation: null
  },
  mutations: {
    [SET_CONSULTATION_LIST]: (state, payload) => {
      state.consultationList = payload
    },
    [SET_CONSULTATION_TYPE_LIST]: (state, payload) => {
      state.consultationTypeList = payload
    },
    [SET_CONSULTATION_PROFILE_BUF]: (state, payload) => {
      state.userConsultation.profile = payload
    },
    [SET_CONSULTATION_DETAIL_BUF]: (state, payload) => {
      state.userConsultation.detail = payload
    },
    [SET_CONSULTATION_FILE_BUF]: (state, payload) => {
      state.userConsultation.files = payload
    },
    [GET_CONSULTATION]: (state, payload) => {
      state.consultation = payload
    },
    [GET_EVALUATE_QUESTION]: (state, payload) => {
      state.questionList = payload
    }

    
  },
  actions: {
    getUserConsultById: async ({ commit }, {id}) => {
      try {
        const resp = await getUserConsultByIdService(id)
        if (resp.status === STATUS_SUCCESS || resp.status === STATUS_OK) {
          commit(GET_CONSULTATION, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    getPublicConsultById: async ({ commit }, {id}) => {
      try {
        const resp = await getPublicConsultByIdService(id)
        if (resp.status === STATUS_SUCCESS || resp.status === STATUS_OK) {
          commit(GET_CONSULTATION, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    getConsultByUserId: async ({ commit }) => {
      try {
        const resp = await getConsultByUserIdService()
        if (resp.status === STATUS_SUCCESS || resp.status === STATUS_OK) {
          commit(SET_CONSULTATION_LIST, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    getAllConsultTopic: async ({ commit }) => {
      try {
        const resp = await getAllConsultTopicService()
        if (resp.status === STATUS_SUCCESS || resp.status === STATUS_OK) {
          commit(SET_CONSULTATION_TYPE_LIST, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    },

    getEvaluateQuestionApi: async ({ commit }) => {
      try {
        commit(GET_EVALUATE_QUESTION, [])
        const resp = await getEvaluateQuestionService()
        if (resp.status === STATUS_SUCCESS || resp.status === STATUS_OK) {
          commit(GET_EVALUATE_QUESTION, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    },

    userRequestConsultation: async ({ commit }, formData) => {
      try {
        const resp = await userRequestConsultationService(formData)
        if(resp){
          if (resp.status === STATUS_SUCCESS) {
            commit(SET_CONSULTATION)
            return true
          } else {
            return false
          }
        }else{
          return false
        }
      } catch (err) {
        console.error(`im err : ${err}`)
        return false
      }
    },
    setUserConsultationProfile : ({ commit }, payload) => {
      console.log(payload)
      commit(SET_CONSULTATION_PROFILE_BUF, payload)
    },
    setUserConsultationDetails : ({ commit },payload) => {
      console.log(payload)
      commit(SET_CONSULTATION_DETAIL_BUF, payload)
    },
    setUserConsultationFiles : ({ commit },payload) => {
      console.log(payload)
      commit(SET_CONSULTATION_FILE_BUF, payload)
    },

    setEvaluationScoreApi: async ({ commit }, {consultation_id, scores, template_id, employee_user_id}) => {
      try {
        const resp = await setEvaluationScoreService(consultation_id, scores, template_id, employee_user_id)
        if (resp.status === STATUS_SUCCESS || resp.status === STATUS_OK) {
          commit(GET_CONSULTATION, resp.data)
          return true
        }
      } catch (error) {
        console.log(error)
        return false
      }
    }
  }
}

export { consultationModule }
