import { ApiService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

export const getNewsListService = async ( keyword, startDate, endDate, sortBy, limit, offset ) => {
  try {
    return await client.post(apiConstant.NEWS_LIST, {
      keyword, startDate, endDate, sortBy, limit, offset
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const getNewsByIdService = async ( id) => {
  try {
    return await client.get(apiConstant.NEWS_BY_ID+'/'+id, {})
  } catch (error) {
    throw new Error(error)
  }
}


