import { SET_NEWSLIST, REQUEST_PROFILE, STATUS_SUCCESS, SET_BLOGLIST } from '@/constants'
import { localStorageService } from '@/utils'
import { getListNewsService, getListBlogService } from '@/services'

const userModule = {
  state: {
    username: localStorageService.getUsername(),
    password: localStorageService.getPassword(),
    userProfile: [],
    blogList: [],
    newsList: [],
  },
  getters: {
    getUsername: (state) => state.username,
    getPassword: (state) => state.password,
    getNews: (state) => state.newsList,
    getBlog: (state) => state.blogList,
    getUserProfile: (state) => state.userProfile,
  },
  mutations: {
    [REQUEST_PROFILE]: (state) => {
      state.username = localStorageService.getUsername()
    },
    [SET_NEWSLIST]: (state, payload) => {
      state.news = payload.user
      if (payload.agent) {
        state.agentNews = payload.agent
      }
    }
  },
  actions: {
    requestUsername: ({ commit }) => {
      commit(REQUEST_PROFILE)
    },
    getNewsList: async ({ commit }) => {
      try {
        const resp = await getListNewsService()
        if (resp.status_code === STATUS_SUCCESS) {
          commit(SET_NEWSLIST, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    getBlogList: async ({ commit }) => {
      try {
        const resp = await getListBlogService()
        if (resp.status_code === STATUS_SUCCESS) {
          commit(SET_BLOGLIST, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    },
  }
}

export {
  userModule
}
