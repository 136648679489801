
export function convertDateToThai (dateString) {
  // Parse the date
  const date = new Date(dateString);

  // Get the day, month, and year
  const day = date.getDate();
  const month = date.toLocaleString('th-TH', { month: 'short' }); // Thai month
  const buddhistYear = date.getFullYear() + 543; // Convert to Buddhist year

  return `${day} ${month} ${buddhistYear}`;
}

export function convertDateTimeToThai(dateString) {
  const date = new Date(dateString);
  if (isNaN(date)) return 'Invalid date';

  // Get the day, month, and year
  const day = date.getDate();
  const month = date.toLocaleString('th-TH', { month: 'short' }); // Thai month
  const buddhistYear = date.getFullYear() + 543; // Convert to Buddhist year

  // Get the time
  const hours = date.getHours().toString().padStart(2, '0'); // Pad with zero if single digit
  const minutes = date.getMinutes().toString().padStart(2, '0'); // Pad with zero if single digit

  return `${day} ${month} ${buddhistYear} ${hours}:${minutes}`;
}