import { localStorageService } from '@/utils'
import {
  SET_TOKEN,
  SET_ISMEMBER,
  CLEAR_SESSION,
  STATUS_SUCCESS,
  REQUEST_PROFILE,
  STATUS_OK,
  SET_USER_ADDRESS,
  SET_ACTIVE,
  SET_REGISTERFLAG
} from '@/constants'
import { authenService, getUserProfileApiService, setUserProfileService, setUserAddressService, setNewPasswordService, getProvinceDistrictsSubdistrictsService, registerCreateUserService } from '@/services'
const authModule = {
  state: {
    userAddress: {},
    isMember: localStorageService.isHasToken(),
    userProfile: null,
    // userConsultation: {
    //   profile: {},
    //   detail: {},
    //   files: {}
    // }
  },
  mutations: {
    [SET_TOKEN]: (_, payload) => {
      localStorageService.setToken(
        payload.payload,
        payload.username,
        payload.password
      )
    },
    [SET_ISMEMBER]: (state, payload) => {
      state.isMember = payload
    },
    [CLEAR_SESSION]: () => {
      localStorageService.clearToken()
    },
    [REQUEST_PROFILE]: (state, payload) => {
      state.userProfile = payload[0] ? payload[0] : payload
    },
    [SET_USER_ADDRESS]: (state, payload) => {
      state.userAddress = payload
    },
    [SET_REGISTERFLAG] : () => {}
  },
  getters: {
    getIsMember: state => state.isMember,
  },
  actions: {
    getUserProfileApi: async ({ commit }) => {
      try {
        commit(REQUEST_PROFILE, {})
        const resp = await getUserProfileApiService()
        if (resp.status === STATUS_SUCCESS || resp.status === STATUS_OK) {
          commit(REQUEST_PROFILE, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    loginAction: async ({ commit }, { username, password, code }) => {
      try {
        const resp = await authenService(username, password, code)
        if(resp){
          if (resp.status === STATUS_SUCCESS) {
            commit(SET_ISMEMBER, true) //TODO: for test
            commit(SET_TOKEN, { payload: resp, username, password }) //TODO: for test
            return true
          } else {
            return false
          }
        }else{
          return false
        }
      } catch (err) {
        console.error(`im err : ${err}`)
        return false
      }
    },
    setUserProfileApi: async ({ commit }, { title_prefix, first_name, last_name, id_card_type, id_number,phone_number, mobile_number,email}) => {
      try {
        const resp = await setUserProfileService(title_prefix, first_name, last_name, id_card_type, id_number,phone_number, mobile_number,email)
        if(resp){
          if (resp.status === STATUS_SUCCESS) {
            commit(SET_ACTIVE)
            return true
          } else {
            return false
          }
        }else{
          return false
        }
      } catch (err) {
        console.error(`im err : ${err}`)
        return false
      }
    },
    setUserAddressApi: async ( { commit },{ address_type,street_address,village,road,alley,province,district,sub_district,postal_code}) => {
      try {
        const resp = await setUserAddressService(address_type,street_address,village,road,alley,province,district,sub_district,postal_code)
        if(resp){
          if (resp.status === STATUS_SUCCESS) {
            commit(SET_ACTIVE)
            return true
          } else {
            return false
          }
        }else{
          return false
        }
      } catch (err) {
        console.error(`im err : ${err}`)
        return false
      }
    },
    setNewPasswordApi: async ( { commit },{ oldPassword, newPassword }) => {
      try {
        const resp = await setNewPasswordService(oldPassword, newPassword)
        if(resp){
          if (resp.status === STATUS_SUCCESS) {
            commit(SET_ACTIVE)
            return true
          } else {
            return false
          }
        }else{
          return false
        }
      } catch (err) {
        console.error(`im err : ${err}`)
        return false
      }
    },
    getProvinceDistrictsSubdistrictsApi: async ({ commit }, { zipCode, language }) => {
      try {
        const resp = await getProvinceDistrictsSubdistrictsService(zipCode, language)
        if(resp){
          if (resp.status === STATUS_SUCCESS) {
            commit(SET_USER_ADDRESS, resp.data)
            return true
          } else {
            return false
          }
        }else{
          return false
        }
      } catch (err) {
        console.error(`im err : ${err}`)
        return false
      }
    },

    setToken: ({ commit }, payload) => {
      commit(SET_TOKEN, payload)
    },
    setIsMember: ({ commit }, payload) => {
      commit(SET_ISMEMBER, payload)
    },
    clearLogOut: ({ commit }) => {
      commit(SET_ISMEMBER, false)
      commit(CLEAR_SESSION)
    },
   registerCreateUser: async ({ commit }, fromData) => {
      try {
        const resp = await registerCreateUserService(fromData)
        if (resp?.status === STATUS_SUCCESS) {
          commit(SET_REGISTERFLAG, fromData)
          return true
        } else {
          return false
        }
      } catch (err) {
        console.error(`im err : ${err}`)
        return false
      }
    }
  }
}

export { authModule }
