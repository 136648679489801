export const localStorageService = (() => {
  let _service

  function _getService () {
    if (!_service) {
      _service = this
      return _service
    }
    return _service
  }

  function _setToken (tokenObj, username, password) {
    localStorage.setItem('accessToken', tokenObj?.data?.accessToken)
    localStorage.setItem('refreshToken', tokenObj?.data?.refreshToken)
    localStorage.setItem('username', username)
    localStorage.setItem('password', password)
  }

  function _setTokenDefault (tokenObj) {
    localStorage.setItem('accessToken', tokenObj?.data?.accessToken)
    localStorage.setItem('refreshToken', tokenObj?.data?.refreshToken)
    localStorage.setItem('client_id', tokenObj?.data?.client_id)
    localStorage.setItem('game_permission', tokenObj?.data?.game_permission)
  }

  function _isHasToken () {
    return !!(
      window?.localStorage?.getItem('accessToken') &&
      window?.localStorage?.getItem('refreshToken')
    )
  }

  function _getAccessToken () {
    return localStorage.getItem('accessToken')
  }

  function _getClientId () {
    return localStorage.getItem('client_id')
  }

  function _getRefreshToken () {
    return localStorage.getItem('refreshToken')
  }

  function _getUsername () {
    return localStorage.getItem('username')
  }

  function _getPassword () {
    return localStorage.getItem('password')
  }

  function _clearToken () {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('client_id')
    localStorage.removeItem('username')
    localStorage.removeItem('password')
  }

  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,
    isHasToken: _isHasToken,
    getUsername: _getUsername,
    getPassword: _getPassword,
    getClientId: _getClientId,
    setTokenDefault: _setTokenDefault,
  }
})()
