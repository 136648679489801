import { ApiService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

export const getConsultByUserIdService = async () => {
  try {
    return await client.get(apiConstant.CONSULTATION_LIST)
  } catch (error) {
    throw new Error(error)
  }
}

export const getAllConsultTopicService = async () => {
  try {
    return await client.get(apiConstant.CONSULTATION_TYPE_LIST)
  } catch (error) {
    throw new Error(error)
  }
}

export const userRequestConsultationService = async (formData) => {
  try {
    return await client.post(apiConstant.SET_CONSULTATION, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const getUserConsultByIdService = async (id) => {
  try {
    return await client.get(apiConstant.GET_CONSULTATION+`/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}
export const getPublicConsultByIdService = async (id) => {
  try {
    return await client.get(apiConstant.GET_PUBLIC_CONSULTATION+`/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}

export const getEvaluateQuestionService = async () => {
  try {
    return await client.get(apiConstant.GET_EVALUATE_QUESTION)
  } catch (error) {
    throw new Error(error)
  }
}

export const setEvaluationScoreService = async (consultation_id, scores, template_id, employee_user_id) => {
  try {
    return await client.post(apiConstant.SET_EVALUATE_SCORE, {consultation_id, scores, template_id, employee_user_id})
  } catch (error) {
    throw new Error(error)
  }
}