import { ApiService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

export const submitForgetService = async payload => {
  try {
    return await client.post(apiConstant.USER_FORGET_PASS, payload)
  } catch (error) {
    throw new Error(error)
  }
}

export const verifyOTPPinService = async payload => {
  try {
    return await client.post(apiConstant.USER_VERIFY_OTP, payload)
  } catch (error) {
    throw new Error(error)
  }
}

export const submitNewPasswordService = async payload => {
  try {
    return await client.post(apiConstant.USER_NEW_PASSWORD, payload)
  } catch (error) {
    throw new Error(error)
  }
}
