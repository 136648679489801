import Vue from 'vue'
import Vuex from 'vuex'
import {
  homeModule,
  userModule,
  authModule,
  errorModule,
  knowledgeModule,
  faqModule,
  newsModule,
  consultationModule
} from '@/store/modules'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    homeModule,
    userModule,
    authModule,
    errorModule,
    knowledgeModule,
    faqModule,
    newsModule,
    consultationModule
  }
})
