export const SET_EMAIL = 'setEmail'
export const SET_TOKEN = 'setToken'
export const SET_ISMEMBER = 'setIsMember'
export const CLEAR_SESSION = 'clearSession'
export const SET_ERRMESSAGE = 'setErrMessage'
export const SET_ACTIVEERROR = 'setActiveError'
export const SET_ACTIVEINFO = 'setActiveInfo'
export const SET_INFOMESSAGE = 'setInfoMessage'
export const SET_NAME = 'setName'
export const SET_LOADING = 'setLoading'
export const REQUEST_PROFILE = 'requestProfile'
export const SET_ACTIVE = 'setActive'
export const SET_REGISTERFLAG = 'setRegister'


/* knowledge */
export const SET_KNOWLEDGELIST = 'setKnowledgeList'
export const SET_KNOWLEDGEL_BY_ID = 'setKnowledgeById'

/* news */
export const SET_NEWSLIST = 'setNewsList'
export const SET_NEWS_BY_ID = 'setNewsById'

/* FAQ */
export const SET_FAQLIST = 'setFaqList'

/* Consultation */
export const GET_CONSULTATION = 'setConsultation'
export const SET_CONSULTATION = 'setConsultation'
export const SET_CONSULTATION_LIST = 'setConsultationList'
export const SET_CONSULTATION_TYPE_LIST = 'setConsultationTypeList'
export const SET_CONSULTATION_PROFILE_BUF = 'setConsultationProfileBuf'
export const SET_CONSULTATION_DETAIL_BUF = 'setConsultationDetailBuf'
export const SET_CONSULTATION_FILE_BUF = 'setConsultationFileBuf'
export const GET_EVALUATE_QUESTION = 'getEvaluateQuestionList'
/* user */
export const SET_USER_ADDRESS = 'setUserAddress'


/* evaluation  */
export const SET_EVALUATION_TEMPLATE = 'setEvaluationTemplate'
export const SET_EVALUATION_QUESTIONS = 'setEvaluationQuestions'
export const SET_EVALUATION_SCORES = 'setEvaluationScores'
export const SET_EVALUATION_DETAILS = 'setEvaluationDetails'
export const SET_ADD_EVALUATION = 'setAddEvaluation'
export const SET_EDIT_EVALUATION = 'setEditEvaluation'
export const SET_DELETE_EVALUATION = 'setDeleteEvaluation'